/* You can add global styles to this file, and also import other style files */
@import '~@angular/cdk/overlay-prebuilt.css'; /** Add this only for non-material project */
@import '~ngx-sharebuttons/themes/default/default-theme';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');


* {
  font-family: 'Open Sans', sans-serif;
}

.bkg-indigo {
  background-image: url("./assets/img/FONDO.jpg");
  height: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  background-attachment: fixed;
  background-size: cover;
}

.bold {
  font-weight: bold;
}

.fix-text {
  text-overflow: Ellipsis;
  width: 270px;
  white-space: nowrap;
  overflow:hidden;
}

.bkg-header-home {
  margin-top: 45px;
  width: 90%;
  background-color: #212529!important;
  height: 85px;
  border-radius: 10px;
}

.bkg-second {
  background-color: #212529!important;
}

.cover-f {
  height:auto;
  overflow:auto;
}

.container-over-y-p {
  overflow:auto;
  height: 100%;
  width: 90%!important;
}

.flex-c {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center!important;
}

.flex-d {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.img-flow {
  object-fit: cover;
  object-position: center center;
  filter: brightness(75%);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.secundary-color{
  color: #31e4a9;
}

.btn-bkg {
  // background-color: #3498db !important;
  // color: white!important;
  // width: 65%;
  // border-radius: 10px;
  // outline: none!important;
  width: 65%;
  height: 45px;
  margin-right: auto;
    margin-left: auto;
    padding: 7px 29px 12px;
    border-radius: 30px;
    background-color: #7c28de;
    box-shadow: 7px 7px 20px 0 rgba(119,60,213,.5);
    font-family: Adlinnaka,sans-serif;
    color: #f4f8fc;
    font-size: 19px;
    font-weight: 700;
}
.text-length {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.offcanvas-bottom-m {
  right: 0;
  left: 0;
  height: 100vh;
  max-height: 100%;
  border-top: 1px solid rgba(0,0,0,.2);
  transform: translateY(100%);
}

.img-project-canvas {
  margin-top: 45px;
  width: 350px;
  height: 550px;
}

.bkg-item {
  width: auto;
  height: auto;
  background-color: white;
  color: black!important;
  border-radius: 5px;
  padding: 5px;
  margin-top: -3px;
  margin-left: 10px;
  margin-bottom: 25px
}

.bkg-sub-data {
  background-color: white;
  color: black!important;
  border-radius: 10px;
  padding: 10px;
  width: 100%!important;
}

.detail-form {
  font-family:'Didact Gothic'!important;
  font-size:18px;
  height: 40px;
  border-radius: 3px!important;
  outline: none!important;
  width:80px;
  border: 1px solid #ccc;
  padding: 5px!Important;
}
.flex-form {
  vertical-align:top;
  flex-direction: row;
  align-items: center;
  align-content: center;
}
.add-text-style {
  font-family: "Didact Gothic"!important;
}

.form-line-error div.form-error-message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #FF4E5D;
  color: #fff;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 10px!important;
}

.multi-select-container {
  display: inline-block;
  position: relative;
}

.multi-select-menu {
  position: absolute;
  left: 0;
  top: 0.8em;
  float: left;
  min-width: 100%;
  background: #fff;
  margin: 1em 0;
  padding: 0.4em 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: none;
}

.multi-select-menu input {
  margin-right: 0.3em;
  vertical-align: 0.1em;
}

.multi-select-button {
  display: inline-block;
  font-size: 0.875em;
  padding: 0.2em 0.6em;
  max-width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: -0.5em;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  cursor: default;
}

.box-options {
  margin-top: 10px;
  padding-left: 5px;
  width: 100%;
  height: 90px;
}


/** SCROLL CSS **/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}



